/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const { LANGUAGES } = require('./src/constants');

exports.onClientEntry = () => {
    const navigatiorLang = navigator.language.substr(0, 2);
    const userLang = LANGUAGES.includes(navigatiorLang) ? navigatiorLang : LANGUAGES[0];

    if (!/\/(en|de)\b/.test(window.location.pathname)) {
        window.location.pathname = `/${userLang}${window.location.pathname}`;
    }
};
