// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audience-analytics-de-js": () => import("./../../../src/pages/audience-analytics.de.js" /* webpackChunkName: "component---src-pages-audience-analytics-de-js" */),
  "component---src-pages-audience-analytics-en-js": () => import("./../../../src/pages/audience-analytics.en.js" /* webpackChunkName: "component---src-pages-audience-analytics-en-js" */),
  "component---src-pages-company-de-js": () => import("./../../../src/pages/company.de.js" /* webpackChunkName: "component---src-pages-company-de-js" */),
  "component---src-pages-company-en-js": () => import("./../../../src/pages/company.en.js" /* webpackChunkName: "component---src-pages-company-en-js" */),
  "component---src-pages-current-performance-de-js": () => import("./../../../src/pages/current-performance.de.js" /* webpackChunkName: "component---src-pages-current-performance-de-js" */),
  "component---src-pages-current-performance-en-js": () => import("./../../../src/pages/current-performance.en.js" /* webpackChunkName: "component---src-pages-current-performance-en-js" */),
  "component---src-pages-foot-traffic-de-js": () => import("./../../../src/pages/foot-traffic.de.js" /* webpackChunkName: "component---src-pages-foot-traffic-de-js" */),
  "component---src-pages-foot-traffic-en-js": () => import("./../../../src/pages/foot-traffic.en.js" /* webpackChunkName: "component---src-pages-foot-traffic-en-js" */),
  "component---src-pages-how-it-works-de-js": () => import("./../../../src/pages/how-it-works.de.js" /* webpackChunkName: "component---src-pages-how-it-works-de-js" */),
  "component---src-pages-how-it-works-en-js": () => import("./../../../src/pages/how-it-works.en.js" /* webpackChunkName: "component---src-pages-how-it-works-en-js" */),
  "component---src-pages-in-store-optimization-de-js": () => import("./../../../src/pages/in-store-optimization.de.js" /* webpackChunkName: "component---src-pages-in-store-optimization-de-js" */),
  "component---src-pages-in-store-optimization-en-js": () => import("./../../../src/pages/in-store-optimization.en.js" /* webpackChunkName: "component---src-pages-in-store-optimization-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-partners-de-js": () => import("./../../../src/pages/partners.de.js" /* webpackChunkName: "component---src-pages-partners-de-js" */),
  "component---src-pages-partners-en-js": () => import("./../../../src/pages/partners.en.js" /* webpackChunkName: "component---src-pages-partners-en-js" */),
  "component---src-pages-performance-analytics-de-js": () => import("./../../../src/pages/performance-analytics.de.js" /* webpackChunkName: "component---src-pages-performance-analytics-de-js" */),
  "component---src-pages-performance-analytics-en-js": () => import("./../../../src/pages/performance-analytics.en.js" /* webpackChunkName: "component---src-pages-performance-analytics-en-js" */),
  "component---src-pages-privacy-de-js": () => import("./../../../src/pages/privacy.de.js" /* webpackChunkName: "component---src-pages-privacy-de-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-request-demo-de-js": () => import("./../../../src/pages/request-demo.de.js" /* webpackChunkName: "component---src-pages-request-demo-de-js" */),
  "component---src-pages-request-demo-en-js": () => import("./../../../src/pages/request-demo.en.js" /* webpackChunkName: "component---src-pages-request-demo-en-js" */)
}

